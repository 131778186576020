.header {
  box-shadow: none !important;
}

input,
button {
  outline: none;
}

tt {
  font-family: "Roboto Mono", Courier, monospace;
}

ul li {
  list-style-type: none;
}

a {
  color: #2196f3;
  text-decoration: none;
}

a:hover,
a:focus {
  /* color: #0a6ebd; */
  /* text-decoration: underline; */
}

a.red:hover,
a.red:focus {
  color: #f44336;
}

a.flat-button {
  display: flex;
  align-items: center;
  margin: 0.15rem 0.35rem;
  height: 2em;
  line-height: 2em;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  white-space: nowrap;
  text-transform: uppercase;
}

a.flat-button:hover,
a.flat-button:focus {
  text-decoration: none;
}

form {
  font-size: 1.1rem;
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
}

label {
  font-size: 1.1rem;
  margin-right: auto;
}

label.small {
  color: #1e88e5;
  font-size: 0.95rem;
}

img.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  background: #ccc;
}

/*button input*/
button.btn-h:active {
  color: #fff;
}

button.btn-h:active {
  box-shadow: inset 0 3px 0.5rem rgba(0, 0, 0, 0.125);
}

button.btn-h:active.round.small {
  box-shadow: inset 0 2px 0.35rem rgba(0, 0, 0, 0.125);
}

button.btn-h.blue {
  background-color: #1e88e5;
  /* blue 600 */
  color: white;
}

button.btn-h.blue:focus,
button.btn-h.blue:hover,
button.btn-h.blue:active {
  background-color: #1976d2;
}

button.btn-h.blue {
  background-color: #1e88e5;
  /* blue 600 */
  color: white;
}

button.btn-h.blue:focus,
button.btn-h.blue:hover,
button.btn-h.blue:active {
  background-color: #2962ff;
}

button.btn-h.white {
  background-color: #fff;
  color: #2196f3;
  /* blue 500 */
}

button.btn-h.white:focus,
button.btn-h.white:hover,
button.btn-h.btn-h.blue:active {
  background-color: #e3f2fd;
}

button.btn-h.outline {
  background-color: transparent;
  color: #2196f3;
  /* blue 500 */
  border: 1px solid silver;
  box-shadow: none;
}

button.btn-h.outline:focus,
button.btn-h.outline:hover,
button.btn-h.outline:active {
  background-color: #e8eaf6;
  /* indigo 50 */
}

button.btn-h {
  display: inline-block;
  font-family: inherit;
  font-size: 1.05rem;
  height: 2.2rem;
  line-height: 2.2rem;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  min-width: 5rem;
  margin: 0 0.35rem 0 0.35rem;
}

/* Input fields and buttons */

.app-chat button[disabled],
.app-chat html input[disabled] {
  cursor: default;
  opacity: 0.65;
  box-shadow: none;
}

.app-chat button.round {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 0;
  margin: 0;
  line-height: 3.5rem;
  outline: none;
  /* otherwise a square outline is shown */
  box-shadow: 0 2px 0.5rem 0 rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.app-chat button.round.small {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  box-shadow: 0 2px 0.35rem 0 rgba(0, 0, 0, 0.25);
}

.app-chat #sendMessage,
.form-add-mes textarea {
  font-family: inherit;
  font-size: 1.1rem;
  background: transparent;
  border: none;
  margin: 0.25rem 0;
  padding: 0 0.125rem;
  outline: none;
  color: #666;
  height: 60px;
  border-bottom: 1px solid #ccc;
  /* box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1); */
  transition: all 0.1s ease-in-out;
  resize: none;
}

.app-chat textarea#sendMessage {
  resize: none;
}

.app-chat input[type="text"],
.app-chat input[type="email"],
.app-chat input[type="password"],
textarea {
  width: 100%;
}

.app-chat textarea#sendMessage:focus,
.form-add-mes textarea:focus {
  border-bottom: 1px solid #2196f3;
  box-shadow: 0 1px 0 rgba(33, 150, 243, 0.25);
}

.app-chat textarea.invalid,
.app-chat input[type="text"].invalid,
.app-chat input[type="email"].invalid,
.app-chat input[type="password"].invalid,
.app-chat input[type="search"].invalid {
  border-bottom: 1px solid #e57373;
  box-shadow: 0 1px 0 rgba(229, 115, 115, 0.25);
}

.app-chat textarea#sendMessage::placeholder,
.form-add-mes textarea::placeholder {
  color: #bbb;
  font-weight: lighter;
}

/* .app-chat input[type="text"].with-visibility,
.app-chat input[type="password"].with-visibility {
  margin-right: -2rem;
  padding-right: 2rem;
} */

/* END of input and buttons */

/* BEGIN scrollbar styling.  */

/* Chrome & other webkit browsers */
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

/* Firefox only */
* {
  scrollbar-width: thin;
  scrollbar-color: silver;
}

/* END of Scrollbar styling.  */

/* BEGIN Popup/dropdown menu */

ul.menu-tool {
  position: absolute;
  list-style: none;
  width: 12rem;
  border-radius: 0.2rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.45);
  background-color: white;
  z-index: 4;
  animation: mes-show-opacity 0.3s ease-in-out;
}

ul.menu-tool > li {
  padding: 0.75rem 0.75rem 0.75rem 1.25rem;
  cursor: pointer;
}

ul.menu-tool > li:hover {
  background-color: #f5f5f5;
}

ul.menu-tool > li.separator {
  padding: 0;
  margin: 2px;
  height: 1px;
  background-color: #ccc;
}

ul.menu-tool > li.separator:hover {
  background-color: #ccc;
}

/* The down arrow head - trigger for context menu */
li .menuTrigger {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 1.5rem;
  height: 1.2rem;
  line-height: 1.2rem;
  border-radius: 0.6rem;
  text-align: center;
  opacity: 0;
  display: flex;
  align-items: center;
}

li:hover .menuTrigger {
  opacity: 1;
}

li .menuTrigger a {
  margin-right: 5px;
}

li .menuTrigger a:hover {
  color: #757575 !important;
}

li .menuTrigger .icon-reply {
  font-size: 22px;
}

li.left:hover .menuTrigger {
  -text-shadow: #c5e1a5 0 0 15px, #c5e1a5 0 0 5px;
  background-image: radial-gradient(#c5e1a5 40%, transparent 75%);
}

li.right:hover .menuTrigger {
  -text-shadow: #fafafa 0 0 15px, #fafafa 0 0 5px;
  background-image: radial-gradient(#fafafa 40%, transparent 75%);
}

/* END popup/dropdown menu */

/* Begin Alert: modal window with message and buttons. */
div.alert-container,
.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  -x-backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.33);
  z-index: 999;
  top: 0;
  left: 0;
}

.alert-container .alert-out {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.popup-container {
  display: none;
}

.popup-container.show {
  display: block;
}

.popup-container input {
  padding: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.popup-fixed {
  position: fixed !important;
  top: 0;
  left: 0;
}

div.alert,
.popup-body {
  background-color: white;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.45);
  padding: 1rem;
  border-radius: 4px;
  margin: auto;
  width: content;
  min-width: 16rem;
  max-width: 24rem;
  z-index: 99;
}

.popup-body {
  min-width: 24rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div.alert .title,
.popup-container .title {
  color: #2196f3;
  font-size: 1.2rem;
  font-weight: 500;
  width: 100%;
  padding: 0 0 0.35rem 0;
}

div.alert .content,
.popup-container .content {
  padding: 0.5rem 0 0.5rem 0;
}

.alert-container button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.alert-container button .loader-spinner {
  border: 0.25rem solid #1e88e5;
  border-radius: 50%;
  border-top: 0.25rem solid #fff;
  border-left: 0.25rem solid #fff;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1.5s linear infinite;
  border-bottom: 0.25rem solid #fff;
  margin-left: 10px;
}

/* END Alert */

/* Generic text colors */
.blue {
  color: #2196f3;
  /* blue 500 */
}

.red {
  color: #d32f2f;
  /* red 700 */
}

.amber {
  color: #ffc107;
  /* amber 500 */
}

.gray {
  color: #757575;
}

.light-gray {
  color: #bbb;
}

.small {
  font-size: 90%;
}

.hr {
  display: block;
  width: 100%;
  height: 0.45rem;
  min-height: 0.3rem;
  background-color: #f0f0f0;
  box-shadow: inset 0 0.25rem 0.25rem -0.25rem rgba(0, 0, 0, 0.15),
    inset 0 -0.25rem 0.25rem -0.25rem rgba(0, 0, 0, 0.15);
}

/* Element which can take focus but shows no outline */
.group-focus {
  outline: none;
}

/* END generic text colors */

/* END of basic elements */

#root-chat {
  height: 100%;
  /* padding-top: 1rem; */
  /* padding-bottom: 3rem; */
}

.login-page {
  background-image: url("../img/ignasi_pattern_s.png");
}

#login-view {
  margin: auto;
  width: 35%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 5px;
}

#login-view form {
  padding: 40px 20px;
}

#login-view #side-caption-panel {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
}
#login-view .ant-form-item {
  margin-bottom: 20px !important;
}

/* Top-level application layout style: sidepanel + main view */
#noscript-message {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  overflow: hidden;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.25rem;
  box-shadow: 1px 2px 0.3rem gray;
  background-color: white;
}

#noscript-title {
  padding: 1rem;
  font-size: 120%;
  font-weight: bolder;
  background-color: #2196f3;
  /* blue 500 */
  color: #bbdefb;
  /* light-blue 100 */
}

#noscript-content {
  padding: 1rem;
}

#sidebar-filter {
  width: 52px;
  height: 100%;
  background-color: #52647a;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0.3;
  /* z-index: 1061; */
}
#sidebar-filter li.user-off:after {
  position: absolute;
  background: #b30909;
  width: 28px;
  height: 2px;
  left: 11px;
  transform: rotate(-120deg);
  top: 27px;
}

.sidebar-nav {
  width: 100%;
}

.sidebar-nav li {
  line-height: 52px;
  text-indent: 1px;
  text-align: center;
  cursor: pointer;
  font-size: 25px;
  color: #97a2af;
  width: 100%;
  position: relative;
}

.sidebar-nav li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.sidebar-selected,
.sidebar-nav li:hover {
  text-decoration: none;
  color: #fff !important;
  background-color: #343f4d;
}

#sidepanel {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0.3;
  width: 24rem;
  min-width: 24rem;
  height: 100%;
  background-color: #fff;
}

#topic-view {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#info-view {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0.3;
  width: 27rem;
  min-width: 27rem;
  height: 100%;
  background-color: #fff;
}
#info-view .source-owner .source-icon img {
  background: #fff;
}
#info-view .about-contact ul li {
  padding: 3px 0px 5px;
  font-size: 14px;
  color: #6d6d6d;
}
#info-view .about-contact ul li:last-child {
  padding-bottom: 0px;
}
#info-view .about-contact ul li i {
  margin-right: 10px;
}

#info-view .source-owner {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

#info-view .source-owner a {
  background: #b0bec5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-left: 10px;
}

#info-view .source-owner span {
  margin-left: 10px;
}

#info-view .source-owner a.active {
  background: #2196f3;
}

#info-view .note .form-note {
  border: none;
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  color: #666;
  width: 100%;
}

#info-view .note .form-note::placeholder {
  color: #999999;
}

#info-view .note .form-note:focus {
  /* outline: none; */
  box-shadow: unset;
}

#info-view .list-note {
  /* max-height: 160px; */
  /* overflow: auto; */
  padding-right: 10px;
}

#info-view .list-note .item-note {
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
}

#info-view .list-note .item-note .avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

#info-view .list-note .item-note .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

#info-view .list-note .item-note .content-note {
  width: 100%;
  padding-left: 10px;
  overflow: hidden;
}

#info-view .list-note .item-note .content-note .user-name {
  margin: 0px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#info-view .list-note .item-note .content-note p {
  font-size: 12px;
  margin: 0px;
  white-space: pre-line;
}

#info-view .list-note .item-note .content-note .head {
  display: flex;
  justify-content: space-between;
}

#info-view .list-note .item-note .content-note .head span {
  color: rgb(74, 74, 74);
  display: flex;
  align-items: center;
  font-size: 13px;
}

#info-view .list-note .item-note .content-note svg {
  display: none;
  font-size: 15px;
  color: #3d5fab;
  cursor: pointer;
  display: inline-block;
  color: #cfd2d3;
  display: none;
}

#info-view .list-note .item-note:hover svg {
  display: block;
  margin-right: 3px;
}
#info-view .list-tags .ant-select-selector {
  border-radius: 5px;
}
#info-view .list-tags .ant-tag {
  border-radius: 5px !important;
  cursor: pointer;
  margin-bottom: 5px;
}
#info-view .label-title {
  font-size: 16px;
  font-weight: 500;
}
#info-view .list-tags .anticon-close {
  vertical-align: unset;
}
#info-view .table-tags tr td {
  padding: 2px 10px;
}
/* Generic announcement text, centered vertically and horizontally */
.center-medium-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.4rem;
}

/* Class to alternatively hide sidepanel/topic-view/info-view */
.nodisplay {
  display: none !important;
}

/* Quoted div */
.quoted {
  margin-left: 1rem;
}

/* BEGIN InPlaceEdit styles */
.in-place-edit {
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  /* height: 2.5rem; */
  /* line-height: 2.25; */
  margin: 5px 0px 5px;
  width: 100%;
  vertical-align: baseline;
  border-bottom: 1px dashed #ddd;
  cursor: pointer;
  padding-bottom: 5px;
}

.in-place-edit.disabled {
  border-bottom: none;
  cursor: default;
}

.in-place-edit.placeholder {
  color: #bbb;
}

.in-place-edit .content {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* END of InPlaceEdit styles */

table.permission-editor,
table {
  width: 100%;
}

table.permission-editor td {
  margin: 0.35rem 0;
  font-size: 1.1rem;
  vertical-align: middle;
}

table.permission-editor td.checkbox {
  text-align: center;
  color: #999;
}

.permission-editor .material-icons {
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.material-icons.clickable {
  cursor: pointer;
  border-bottom: none;
}

.clickable {
  border-bottom: 1px dashed #ddd;
  cursor: pointer;
}

.image-clickable {
  cursor: pointer;
}

.clean-clickable {
  cursor: pointer;
}

/* END of top-level layout */

/* Sidepanel styles */
.panel-title {
  font-size: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.caption-panel {
  display: flex;
  align-items: center;
  flex: none;
  padding: 0.5rem;
  height: 48px;
  white-space: nowrap;
  box-shadow: 0 1px 2px gray;
  overflow: hidden;
  z-index: 1;
  /* otherwise the shadow becomes invisible */
}

.dialog-buttons {
  display: flex;
  padding: 0.5rem;
  justify-content: flex-end;
  align-items: center;
}

.dialog-buttons button {
  margin: 0 0.5rem;
  outline: none;
}

.avatar-box {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}

.bot-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 8rem;
  line-height: 2rem;
  padding: 0.35rem;
}

.bot-form div {
  min-height: 2rem;
  vertical-align: middle;
}

.bot-form button {
  display: inline-block;
  margin: 0.15rem;
  width: 100%;
  background-color: #eef;
  color: #2196f3;
  /* blue 500 */
  font-size: inherit;
  text-transform: none;
  height: 2rem;
  line-height: 2rem;
}

.bot-form > div > button {
  width: auto;
}

.bot-form button:focus,
.bot-form button:hover,
.bot-form button:active {
  outline: 0;
  background-color: #e3f2fd;
}

#side-caption-panel {
  background-color: #2196f3;
  /* blue 500 */
  color: #bbdefb;
  /* light-blue 100 */
}

#side-caption-panel a {
  color: #bbdefb;
  /* blue 100 */
  padding-left: 0.5rem;
  line-height: 1;
}

#side-caption-panel :hover {
  color: white;
}

#sidepanel-title {
  margin-right: auto;
  margin-left: 0.5rem;
}

#self-avatar {
  width: 35px;
  min-width: 35px;
  height: 35px;
  flex: none;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 3px 6px rgb(0 0 0 / 30%);
}

#self-avatar.avatar-box {
  font-size: 1.8rem;
}

/* Application settings */
#settings-form ul {
  list-style: none;
}

#settings-form li {
  vertical-align: middle;
}

#settings-form label {
  display: inline-block;
  padding-left: 0.5rem;
}

/* Input with a search icon on the left */
input.search {
  text-indent: 1.4rem;
}

i.search {
  position: absolute;
  left: 0.6rem;
}

/* Area which displays an error message */
.info-box {
  position: relative;
  display: none;
  overflow: hidden;
  padding: 1rem 1.5rem 1rem 0.5rem;
}

.info-box .cancel {
  position: absolute;
  right: 0.25rem;
  top: 0.5rem;
}

.info-box.error {
  display: flex;
  background-color: #ffcdd2;
  border-bottom: 1px solid #ffbfc8;
  color: #b71c1c;
}

.info-box.warning {
  display: flex;
  background-color: #fff9c4;
  border-bottom: 1px solid #fff59d;
  color: #6d4c41;
}

.info-box.info {
  display: flex;
  background-color: #e1f5fe;
  border-bottom: 1px solid #b3e5fc;
  color: #0288d1;
}

.info-box .cancel .material-icons {
  font-size: 1rem;
  color: #757575;
}

.info-box.error .cancel .material-icons {
  color: #b71c1c;
}

.info-box .icon {
  display: block;
  margin-right: 0.5rem;
}

.info-box .icon .material-icons {
  font-size: 1.5rem;
}

.info-box.warning .icon .material-icons {
  color: #fbc02d;
}

.info-box.error .icon .material-icons {
  color: #ef5350;
}

.info-box.info .icon .material-icons {
  color: #0288d1;
}

/* List of contacts */
.flex-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.scrollable-panel {
  overflow-y: auto;
  /* margin-top: 0.25rem; */
  margin-bottom: auto;
  height: 100%;
  position: relative;
  padding-bottom: 20px;
}

/* .scrollable-panel::-webkit-scrollbar{
    display: none;
  }
  .scrollable-panel:hover::-webkit-scrollbar{
    display: block;
  } */
.contact-box {
  list-style: none;
  overflow-x: hidden;
}
.contact-box > li a:hover {
  text-decoration: none !important;
}
.contact-box > li a {
  display: flex;
  position: relative;
  padding: 7px 0.25rem 7px 10px;
  min-height: 3.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom: 1px solid #eee;
  border-collapse: collapse;
  overflow: hidden;
  cursor: pointer;
  color: #666;
  align-items: center;
}

.contact-box > li:first-child {
  /* padding-top: 0.6rem; */
}

.contact-box > li:last-child {
  border-bottom: none;
  /* remove border from the last contact */
}

.contact-box > li:hover {
  background-color: #eceff1;
  /* blue-gray 50 highlight */
}

.contact-box > li.selected {
  background-color: #cfd8dc;
  /* blue-gray 100 highlight */
}

.contact-box > li.action {
  min-height: 2.5rem;
  border-bottom: none;
}

/* .contact-box > li.pending{
    background-color: rgba(255, 245, 162, 0.5);
  }
  .contact-box > li.checkpoint, .contact-box > li.wrong_pass{
    background-color: rgba(255,5,5,0.5);
    color: #fff;
  } */
/* .contact-box > li.pending .contact-comment{
    background: #ffeb3b;
    padding: 2px 5px;
    border-radius: 5px;
    border: none;
  }
  .contact-box > li.checkpoint .contact-comment, .contact-box > li.wrong_pass .contact-comment{
    background: red;
    padding: 2px 5px;
    border-radius: 5px;
    border: none;
    color: #fff;
  } */

.contact-box .text-box {
  overflow: hidden;
}

.contact-box .contact-title {
  font-size: 1.05rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.contact-box .contact-comment,
.contact-box .sidepanel-item-note {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: lighter;
  user-select: none;
  width: 100%;
}

.contact-box .sidepanel-item-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}

.contact-box .sidepanel-item-note .note-content {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: lighter;
  user-select: none;
  margin-right: 5px;
  font-size: 13px;
}

.contact-box .sidepanel-item-note .note-time {
  font-size: 13px;
}

.logo-view {
  user-select: none;
}

/* Avatar and online-offline indicator */
.contact-box .avatar-box {
  margin-right: 0.35rem;
  position: relative;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  font-size: 1.8rem;
  /* overflow: hidden; */
  padding: 0;
  margin-right: 0.9rem;
}

.contact-box .avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

#sidepanel .contact-box .avatar {
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

#sidepanel .contact-box .avatar-ready {
  opacity: 1 !important;
}

.contact-box .avatar-box > .material-icons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 2.4rem;
  line-height: 3rem;
}

.contact-box .text-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
}

.contact-box .action-text {
  text-align: center;
  font-size: 0.95rem;
  line-height: 2.5rem;
  margin: 0 auto 0 auto;
  cursor: pointer;
}

/* Online-offline indicator positioned inside avatar-box */
.avatar-box .online,
.offline,
.status-account {
  display: inline-block;
  width: 0.75rem;
  min-width: 0.75rem;
  height: 0.75rem;
  min-height: 0.75rem;
  flex-shrink: 0;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0.1rem;
}

.contact-box > li.pending .status-account {
  background-color: #ffeb3b;
}

.contact-box > li .status-account {
  background-color: #4caf50;
}

.contact-box > li.wrong_pass .status-account {
  background-color: red;
}

.contact-box > li.wrong .status-account {
  background-color: red;
}

.contact-box > li.checkpoint .status-account {
  background-color: red;
}

.avatar-box .online {
  background-color: #4caf50;
}

.avatar-box .offline {
  background-color: #c0c0c0;
}

/* Typing indicator: animation of the online dot */
.online.typing {
  animation: typing 0.5s infinite;
}

@keyframes typing {
  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-0.3rem);
  }
}

.contact-box .checkmark.material-icons {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #0097a7;
  position: absolute;
  right: 0.05rem;
  bottom: 0.05rem;
  background-color: white;
  overflow: visible;
  border: 0px solid transparent;
}

/* END of avatar box */

#add-topic {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

/* Various forms inside a panel (signup form, new topic form) */
.panel-form {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.panel-form select {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #d4dbe0;
  outline: none;
}

.panel-form-row {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  overflow: hidden;
}

.panel-form-column {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  width: 100%;
}

/* Make sure padding is only applied at
     the top level panel */
.panel-form-row > .panel-form-column,
.panel-form-column > .panel-form-column {
  padding: 0 !important;
  flex-grow: 1;
}

.panel-form-column > .panel-form-row,
.panel-form-row > .panel-form-row {
  padding: 0 !important;
}

.panel-form input {
  /* padding-left: 10px; */
}

.panel-form .icon-r {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

/* Styling of avatar upload control */
.avatar-upload {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: 0.5rem;
  font-size: 4.5rem;
  padding: 0;
  width: 7rem;
  height: 7rem;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.avatar-upload .clear-avatar {
  position: absolute;
  font-size: 1rem;
  top: 0;
  right: 0;
  opacity: 0;
  background-image: radial-gradient(#fff 40%, transparent 75%);
}

.avatar-upload:hover .clear-avatar {
  opacity: 1;
}

.avatar-upload .avatar-box {
  position: relative;
  width: 7rem;
  height: 7rem;
}

.avatar-upload .avatar-box > .material-icons {
  position: relative;
  font-size: 4rem;
  line-height: 7rem;
  vertical-align: middle;
}

/* Clearfix */
.avatar-upload:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.avatar-upload .blank {
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #999;
  font-size: 1rem;
  flex-shrink: 0;
  width: 7rem;
  height: 7rem;
  overflow: hidden;
  color: #999;
  line-height: 7rem;
  border-radius: 50%;
}

.avatar-upload .preview {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-upload .inputfile.hidden {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.avatar-upload .inputfile + label.round {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  margin-left: auto;
  margin-right: auto;
  margin-top: -1rem;
  bottom: 0;
  background-color: #fafafa;
}

.avatar-upload .inputfile:focus + label.round {
  outline: none;
}

.avatar-upload .inputfile:focus + label,
.avatar-upload .inputfile + label:hover {
  background-color: #e3f2fd;
  color: #42a5f5;
}

/* END of avatar upload */
/* END of new account panel */
/* END of sidepanel styles */

/* Topic/messages view */
#topic-caption-panel {
  background-color: #b0bec5;
}

#topic-caption-panel a {
  color: #757575;
  text-decoration: none;
}

#topic-caption-panel :hover .topic-title-group {
  color: black;
}

#topic-caption-panel .avatar-box {
  position: relative;
  width: 40px;
  min-width: 40px;
  height: 40px;
  font-size: 1.8rem;
  margin-right: 0.35rem;
}

#topic-caption-panel .avatar-box > .material-icons {
  font-size: 2.4rem;
  line-height: 3rem;
}

#topic-title-group {
  overflow-x: hidden;
}

#hide-message-view {
  display: none;
  margin-right: 0.35rem;
}

#topic-users {
  margin-left: auto;
  margin-right: 1rem;
}

#topic-users .avatar-box {
  display: inline-block;
  width: 1.8rem;
  min-width: 1.8rem;
  height: 1.8rem;
  min-height: 1.8rem;
  font-size: 1rem;
  margin-right: 0.2rem;
}

#topic-users .avatar-box > .material-icons {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

/* Overflow span "+18 more" */
#topic-users > span {
  line-height: 2rem;
  vertical-align: top;
}

/* Scrollable panel where the messages are shown */
#messages-panel {
  position: relative;
  /* padding: 0.75rem 0px; */
  overflow-y: scroll;
  background-color: #eceff1;
  height: 100%;
  background-image: url("../img/ignasi_pattern_s.png");
  /* scroll-behavior: smooth; */
}

#messages-panel.scroll-smooth{
  scroll-behavior: smooth;
}

#messages-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  flex: 1;
}

#write-only-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5) 0.5rem,
    rgba(255, 255, 255, 0.8) 0.5rem,
    rgba(255, 255, 255, 0.8) 1rem
  );
}

#write-only-note {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: #666;
  height: auto;
  width: auto;
  line-height: 2rem;
  text-align: center;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.12);
}

#peer-messaging-disabled-note {
  position: absolute;
  left: 50%;
  bottom: 4.3rem;
  transform: translate(-50%);
  background-color: white;
  color: #666;
  width: fit-content;
  line-height: 2rem;
  text-align: center;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.12);
}

/* Footer-panel with the input for the new message */
#send-message-panel {
  /* display: flex; */
  /* align-items: center; */
  /* flex: none; */
  padding: 0.4rem 0.4rem 0.4rem 0.6rem;
  background-color: #eceff1;
  box-shadow: 0px -2px 3px -1px rgba(0, 0, 0, 0.1);
  /* Faint shadow above panel */
  z-index: 2;
}

#sendMessage {
  /* overflow: hidden; */
  overflow-wrap: break-word;
  overflow-wrap: break-word;
  height: 100%;
  box-sizing: border-box;
  padding-top: 10px;
}

#send-message-panel .material-icons {
  min-width: 1.75rem;
}

#send-message-panel .material-icons.secondary,
.material-icons.disabled {
  color: #999 !important;
}

#send-message-panel .material-icons.secondary:hover {
  color: #2196f3 !important;
}

/* New chat invitation panel */

.accept-invite-panel {
  background-color: #eceff1;
  box-shadow: 0px -2px 3px -1px rgba(0, 0, 0, 0.1);
  /* Faint shadow above panel */
  width: 100%;
  z-index: 1;
}

.accept-invite-panel .title {
  font-size: 1.2rem;
  padding: 0.75rem;
}

.accept-invite-panel .footer {
  background-color: #fafafa;
  white-space: nowrap;
  padding-bottom: 0.35rem;
  text-align: center;
}

.accept-invite-panel button {
  font-size: 1rem;
  height: 2em;
  line-height: 2em;
  border: 1px solid transparent;
  margin: 0.25rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

/* END of chat invitation panel */

/* A static panel shown when no topic is selected */
#dummy-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef2f5;
}

#dummy-view a {
  text-decoration: none;
  display: block;
  text-align: center;
  vertical-align: middle;
}

#image-preview {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
}

#image-preview-caption-panel {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  line-height: 2.5rem;
  height: 3.5rem;
  padding: 1rem 0.75rem 0 0.75rem;
}

#image-preview-caption-panel a {
  color: #757575;
  text-decoration: none;
}

#image-preview-caption-panel a:hover {
  color: black;
}

#image-preview-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#image-preview-footer {
  display: flex;
  justify-content: space-around;
  height: 3.2rem;
  line-height: 1.5rem;
  background-color: transparent;
}

/* Talk bubbles */
.chat-box {
  list-style: none;
  padding: 10px 0 3.5rem 0;
  margin: 0 0.5rem;
  padding-bottom: 100px;
}

/* Group chats have avatars on the left. Make left margin smaller */
.chat-box.group {
  margin-left: -0.4rem;
}

.chat-box li {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: flex-end;
  /* animation: mes-show-opacity 0.3s ease-in-out; */
  overflow: hidden;
  transition: 0.2s all ease-in-out;
  opacity: 1;
  position: relative;
}

.chat-box li.pending .bubble {
  opacity: 0.5;
}

.chat-box .user-send {
  margin-bottom: 5px;
  padding-right: 10px;
  cursor: pointer;
}
.chat-box .user-send:hover {
  text-decoration: underline;
}

@keyframes mes-show-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.chat-box .right {
  justify-content: flex-end;
}

.chat-box .bubble {
  /* display: flex; */
  position: relative;
  max-width: 80%;
  min-width: 14rem;
  height: auto;
  padding: 6px 0.5rem 10px 1.25rem;
  margin: 0;
  text-align: left;
  border-radius: 0.5rem;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.12);
  transition: 0.2s all ease-in-out;
  opacity: 1;
  width: fit-content;
}

.chat-box .box-content {
  max-width: 100%;
  width: 100%;
}

.chat-box .box-content .link{
  color: #53647a;
  text-decoration: underline;
}

.chat-box .box-content .error-mes {
  clear: both;
  display: flex;
  justify-content: flex-end;
  padding: 7px 0px 5px;
  font-size: 12px;
  align-items: center;
  color: #bb0000;
}

.chat-box .box-content .error-mes i {
  font-size: 12px;
  margin-left: 8px;
}

.chat-box li.send-status .bubble {
  pointer-events: none;
}

.chat-box li.send-status .bubble {
  opacity: 0.6;
}

.chat-box li .bottom-mes {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  font-size: 13px;
  padding: 7px 0px 0px;
}

.chat-box li .bottom-mes .reload {
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.chat-box li .bottom-mes .reload:hover {
  text-decoration: underline;
}

.chat-box li .bottom-mes .reload i {
  font-size: 14px;
  margin-right: 5px;
}

.chat-box .box-content .status-mes {
  clear: both;
  display: flex;
  font-size: 12px;
  align-items: center;
  /* background: #506dad; */
  border-radius: 3px;
  justify-content: flex-end;
  color: #52647a;
  font-weight: 400;
}

.chat-box .box-content .status-mes i {
  font-size: 12px;
  margin: 0px 8px;
}

.chat-box .box-content .status-mes.error {
  color: #bb0000;
}
.chat-box li {
  padding: 0px 20px 0px 15px;
}
.chat-box li.single {
  padding-top: 0.15rem;
  padding-bottom: 0.75rem;
}

.chat-box li.first {
  padding-top: 0.15rem;
  padding-bottom: 0.75rem;
}

.chat-box li.middle {
  padding-top: 0.15rem;
  padding-bottom: 0.75rem;
}

.chat-box li.last {
  padding-top: 0.15rem;
  padding-bottom: 0.75rem;
}

.chat-box .left .bubble {
  background-color: #c5e1a5;
}

.chat-box .right .bubble {
  background-color: #fafafa;
  float: right;
}

/* Left triangle placed bottom left flush. */
.chat-box .left .tip::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: auto;
  left: -0.8rem;
  top: auto;
  bottom: 0;
  border: 0.62rem solid #c5e1a5;
  color: #c5e1a5;
  border-top-color: transparent;
  border-left-color: transparent;
  box-shadow: 0 1.6px 0.5px -0.8px rgba(0, 0, 0, 0.12);
}

.chat-box .left.reply {
  margin-left: 1.8rem;
  border-left: 2px solid #2196f3;
  padding-left: 10px;
}

/* Right triangle placed bottom right flush. */
.chat-box .right .tip::before {
  content: "";
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 0;
  height: 0;
  left: auto;
  right: -0.8rem;
  top: auto;
  bottom: 0;
  border: 0.62rem solid #fafafa;
  color: #fafafa;
  border-top-color: transparent;
  border-right-color: transparent;
  box-shadow: 0 1.6px 0.5px -0.8px rgba(0, 0, 0, 0.12);
}

.chat-box .avatar-box {
  position: relative;
  width: 1.4rem;
  min-width: 1.4rem;
  height: 1.4rem;
  min-height: 1.4rem;
  font-size: 0.8rem;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
}

.chat-box .bubble div.message-content {
  color: #212121;
  margin: 0;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: pre-line;
}

/* The timestamp is a floating element. Need to ensure div.message-content is expanded
   * to accomodate it. It's usually called clearfix. */
.chat-box .bubble div.message-content:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.chat-box .timestamp {
  /* display: inline-block; */
  position: relative;
  font-size: 80%;
  color: #777;
  padding-left: 1rem;
  top: 0.2rem;
  /* float: right; */
  white-space: nowrap;
  user-select: none;
  display: block;
  text-align: right;
}

/* Typing notification bubble */
.chat-box .typing {
  display: inline-block;
  border-radius: 0.5rem;
  width: 2rem;
  padding: 0.25rem 0.35rem;
  vertical-align: middle;
  text-align: center;
  color: #aaa;
  margin-top: 0.75rem;
}

/* The name of the person who sent the message
   * (group chat only)
   */
.chat-box .author {
  font-size: 80%;
  color: #777;
}

.chat-box .handle-sale .box-content {
  width: 100%;
  display: flex;
  justify-content: center;
}
.chat-box .handle-sale .timestamp {
  top: 0;
}
.chat-box .handle-sale .bubble {
  background: rgba(79, 109, 172, 1);
  padding-right: 10px;
  padding-bottom: 5px;
}
.chat-box .handle-sale .timestamp {
  float: unset;
}
.chat-box .handle-sale a {
  color: #fff;
  text-decoration: underline;
}

.chat-box .file .file-name{
  font-size: 14px;
  margin-left: 8px;
}

/* Inline image styles */
.chat-box .inline-image {
  width: auto !important;
  max-width: 36rem !important;
  max-height: 24rem;
  overflow: hidden;
  border-radius: 0.1rem;
  display: block;
}

.chat-box .attachment{
  /* width: 100%; */
  padding-right: 10px;
  width: fit-content;
  display: inline-flex;
}
.chat-box .attachment .inline-image {
  /* max-width: 100%!important; */
}
.chat-box .attachment.attachment-mutiple{
  flex-wrap: wrap;
  /* width: fit-content; */
  margin-left: -3px;
  margin-right: -3px;
}
.chat-box .attachment.attachment-mutiple .ant-image{
  width: 33.33333%;
  max-width: 200px;
  max-height: 200px;
  padding: 3px;
}
.chat-box .attachment.attachment-mutiple .inline-image{
  height: 100%;
  width: 100%!important;
  max-width: 100%!important;
  max-height: 100%!important;
  object-fit: cover;
}

/* Attachment styles */
.attachment {
  display: flex;
}

.attachment:last-of-type {
  margin-bottom: -0.45rem;
}

/* END of talk bubbles */
/* END of topic/mesages view */

/* InfoView styles */
#info-caption-panel {
  background-color: #b0bec5;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  /* border-left: 1px solid #fff; */
  background-color: #ebebeb;
  min-height: 50px;
}

#info-caption-panel a {
  color: #757575;
  line-height: 1;
  text-decoration: none;
}

#info-caption-panel :hover {
  color: black;
}
#info-caption-panel .info-tab {
  flex: 0 0 50%;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
#info-caption-panel .info-tab.active::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 3px;
  background: #506dad;
}
#info-title {
  margin-right: auto;
}

#group-manager {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#group-manager-buttons {
  display: flex;
  background-color: #eceff1;
  box-shadow: 0 -1px rgba(0, 0, 0, 0.1);
  /* Faint shadow above panel */
  z-index: 2;
  justify-content: space-around;
  padding: 0.4rem;
  height: 4rem;
  border-left: 1px solid #ccc;
}

/* END of InfoView */

/* BEGIN Tabs */

ul.tabbar {
  flex-shrink: 0;
  flex-grow: 0;
  background: #eeeeee;
  list-style: none;
  margin: 0;
  width: 100%;
  overflow: hidden;
  padding: 0;
  padding-left: 1rem;
}

ul.tabbar > li {
  float: left;
}

ul.tabbar > li > a {
  position: relative;
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: block;
  text-align: center;
  line-height: 2rem;
  font-weight: 400;
  font-size: 1.3rem;
  font-variant: small-caps;
  text-decoration: none;
  color: rgba(33, 150, 243, 0.6);
  overflow: hidden;
  box-shadow: inset 0 -2px 2.5px -1px rgba(0, 0, 0, 0.25);
}

ul.tabbar li.active > a {
  color: #2196f3;
}

ul.tabbar li.active > a:after {
  height: 0.15rem;
  display: block;
  content: "";
  bottom: 0;
  left: 0;
  position: absolute;
  background: #2196f3;
  animation: tabbar-border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0s alternate
    forwards;
}

@keyframes tabbar-border-expand {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}

/* END Tabs */

/* Miscellaneous */

/* Badge with the number of unread messages */
.unread {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: bolder;
  background-color: #0097a7;
  color: white;
  border-radius: 50%;
  width: 1.35rem;
  height: 1.35rem;
  margin-left: 0.5rem;
  line-height: 1.35rem;
  text-align: center;
  vertical-align: middle;
}

.material-icons.as-badge {
  padding-left: 0.2rem;
  font-size: 1.25rem;
  color: #bbb;
}

/* Badge with a label, like [you], [muted], etc */
/* .badge {
  font-family: "Roboto Mono", Courier, monospace, sans-serif;
  font-size: 0.8rem;
  border: 1px solid silver;
  background-color: #e8e8e8;
  color: #666;
  padding: 0.05rem 0.25rem 0.05rem 0.25rem;
  margin: 0.05rem 0.1rem 0.05rem 0.1rem;
  border-radius: 0.2rem;
  display: inline-block;
  align-self: flex-start;
} */

.item-tag-h {
  font-family: "Roboto Mono", Courier, monospace, sans-serif;
  font-size: 11.5px;
  background-color: #2196f3;
  color: #666;
  padding: 0.05rem 0.25rem 0.05rem 0.25rem;
  margin: 0.05rem 0.1rem 0.05rem 0.1rem;
  border-radius: 0.2rem;
  display: inline-block;
  align-self: flex-start;
  color: #fff;
  cursor: pointer;
  opacity: 0.3;
  user-select: none;
  transition: 0.2s all ease-in-out;
}

.item-tag-h:hover {
  opacity: 1;
}

.item-tag-h.active {
  opacity: 1;
}

.badge.green {
  border-color: #9c9;
  background-color: #efe;
  color: #383;
}

.badge.yellow {
  border-color: #cc9;
  background-color: #ffe;
  color: #660;
}

.badge.blue {
  border-color: #99c;
  background-color: #eef;
  color: #339;
}

.badge.red {
  border-color: #c99;
  background-color: #fee;
  color: #933;
}

.badge.magenta {
  border-color: #c9c;
  background-color: #fef;
  color: #939;
}

/* Letter tile base style, responsive square box */
.lettertile {
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
}

/* Div with letter tile content */
.lettertile > div {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  line-height: 1;
  color: rgba(250, 250, 250, 0.8);
  /* default text color #FAFAFA, gray 50 */
}

.lettertile:hover {
  color: white;
}

/* Colors of letter tiles */
.light-color0 {
  background-color: #ef9a9a;
}

.light-color1 {
  background-color: #90caf9;
}

.light-color2 {
  background-color: #b0bec5;
}

.light-color3 {
  background-color: #b39ddb;
}

.light-color4 {
  background-color: #ffab91;
}

.light-color5 {
  background-color: #a5d6a7;
}

.light-color6 {
  background-color: #eeeeee;
}

.light-color7 {
  background-color: #e6ee9c;
}

.light-color8 {
  background-color: #c5e1a5;
}

.light-color9 {
  background-color: #f0e57d;
}

.light-color10 {
  background-color: #f48fb1;
}

.light-color11 {
  background-color: #9fa8da;
}

.light-color12 {
  background-color: #ffe082;
}

.light-color13 {
  background-color: #bcaaa4;
}

.light-color14 {
  background-color: #80deea;
}

.light-color15 {
  background-color: #ce93d8;
}

/* Darker version of letter tile colors */
.dark-color0 {
  background-color: #c62828;
}

.dark-color1 {
  background-color: #ad1457;
}

.dark-color2 {
  background-color: #6a1b9a;
}

.dark-color3 {
  background-color: #4527a0;
}

.dark-color4 {
  background-color: #283593;
}

.dark-color5 {
  background-color: #1565c0;
}

.dark-color6 {
  background-color: #0277bd;
}

.dark-color7 {
  background-color: #00838f;
}

.dark-color8 {
  background-color: #00695c;
}

.dark-color9 {
  background-color: #2e7d32;
}

.dark-color10 {
  background-color: #558b2f;
}

.dark-color11 {
  background-color: #9e9d24;
}

.dark-color12 {
  background-color: #f9a825;
}

.dark-color13 {
  background-color: #ff8f00;
}

.dark-color14 {
  background-color: #ef6c00;
}

.dark-color15 {
  background-color: #d84315;
}

/* Material icons resized from the default 24pt */
.material-icons {
  font-size: 1.4rem;
  line-height: 1;
  vertical-align: middle;
  text-align: center;
}

.material-icons.small {
  font-size: 1rem;
}

.material-icons.big {
  font-size: 2.4rem;
}

#self-avatar .material-icons {
  font-size: 2.4rem;
  line-height: 3rem;
}

.avatar-box .material-icons {
  color: rgba(250, 250, 250, 0.8);
  /* #fafafa, gray 50 */
  border-radius: 50%;
  width: inherit;
  height: inherit;
}

.chip-input {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  cursor: text;
  font-size: 1.1rem;
  font-weight: normal;
  background: transparent;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-in-out;
  max-height: 10rem;
  min-height: 4rem;
  overflow-y: scroll;
  margin: 0.25rem 0 0.25rem 0;
  outline: none;
  color: #666;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.chip-input.focused {
  border-bottom: 1px solid #2196f3;
  box-shadow: 0 1px 0 rgba(33, 150, 243, 0.25);
}

.chip-input > input[type="text"] {
  display: inline-block;
  width: auto;
  border: none;
  line-height: 1.8;
  box-shadow: none;
  min-width: 4rem;
  flex: 1 0 auto;
  height: 1.8rem;
  margin: 0.125rem;
}

.chip {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: 1.8rem;
  line-height: 1.8;
  border-radius: 0.9rem;
  margin: 0.125rem;
  background-color: #eee;
  white-space: nowrap;
  font-size: 90%;
}

.chip.invalid {
  color: #c66;
  background-color: #fee;
}

.chip > a {
  display: inline-block;
  color: #ccc;
  background-color: #aaa;
  border-radius: 50%;
  margin: 0.3rem;
  width: 1.175rem;
  height: 1.175rem;
  line-height: 1.15;
  text-decoration: none;
}

.chip > a:focus,
.chip > a:hover {
  text-decoration: none;
  color: #ddd;
  background-color: #bbb;
}

.chip .avatar-box {
  position: relative;
  width: 1.8rem;
  min-width: 1.8rem;
  height: 1.8rem;
  min-height: 1.8rem;
  font-size: 1rem;
  margin-right: 0.2rem;
}

.chip .avatar-box .material-icons {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.chip .spacer {
  display: inline-block;
  margin: 0.3rem;
  width: 0.25rem;
  height: 1.175rem;
}

.load-spinner-box {
  position: absolute;
  box-shadow: 0.1rem 0.1rem 0.15rem 1px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  padding: 0.25rem;
  width: 2.5rem;
  height: 2.5rem;
  left: 0;
  right: 0;
  top: 5rem;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  z-index: 2;
}

.load-spin-box {
  position: absolute;
  z-index: 3;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
}

#sidepanel .load-spin-box {
  top: 10rem;
}

.loader-spinner {
  border: 0.35rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 0.35rem solid #1e88e5;
  width: 2rem;
  height: 2rem;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* File upload styles */
.uploader {
  display: flex;
}

/* Gray uploader bar of fixed length (background) */
.uploader > div {
  position: relative;
  height: 0.6rem;
  padding: 0.15rem;
  margin: 0.35rem;
  width: 6rem;
  line-height: 0;
  border-radius: 0.1rem;
  background-color: #cfd8dc;
}

/* Colored uploader progress bar of variable length */
.uploader > div > span {
  display: inline-block;
  height: 0.3rem;
  background-color: #1e88e5;
  border-radius: 0.08rem;
}

.read-contact,
.read-contact .contact-comment {
  font-weight: bold;
}

.image-message {
  max-width: 100%;
}

.audio {
  height: 30px;
  width: 120px;
  outline: none;
}

.chat-box .menuTrigger a {
  cursor: pointer;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101010;
  margin: 0px;
}

.chat-box .menuTrigger a:hover {
  background: #d1e2fa;
  border: 1px solid #d1e2fa;
  border-radius: 50%;
  color: #101010;
}

.chat-box .menuTrigger a.active {
  background: #d1e2fa;
  border: 1px solid #d1e2fa;
  border-radius: 50%;
  color: #101010;
}

.chat-box .menuTrigger a.active svg {
  fill: #101010;
}

.chat-box .menuTrigger {
  top: 50%;
  right: 0px;
  padding: 3px 4px;
  display: flex;
  align-items: center;
  width: fit-content;
  height: auto;
  transform: translate(100%, -50%);
  padding-left: 20px;
}

.chat-box .menuTrigger svg {
  opacity: 0.6;
  height: 14px;
  width: 14px;
  color: #101010;
  pointer-events: none;
}

.chat-box .right .menuTrigger {
  right: unset;
  left: 0;
  transform: translate(-100%, -50%);
  padding-left: 0px;
  padding-right: 20px;
}

.chat-box:hover .menuTrigger {
  background-image: unset !important;
}

#send-message-panel {
  position: relative;
}

#send-message-panel .alert-reply {
  /* position: absolute; */
  /* top: -42px; */
  font-size: 12px;
  background: #cfd8dc;
  border-radius: 10px;
  padding: 2px 10px 2px 3px;
  /* left: 50px; */
  /* color: #999; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
  display: inline-block;
  display: flex;
  align-items: center;
  width: fit-content;
}

#send-message-panel .alert-reply .title {
  /* color: #212121; */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

#send-message-panel .alert-reply .material-icons.reply {
  color: #2196f3;
}

#send-message-panel .alert-reply .material-icons.close {
  font-size: 15px;
  cursor: pointer;
}

.contact-box .avatar-box .icon-type {
  position: absolute;
  right: -7px;
  z-index: 1;
  bottom: -3px;
}

.contact-box .avatar-box .icon-type .material-icons {
  color: #2196f3;
  font-size: 18px;
}

.contact-box .avatar-box .icon-type.icon-cmt .material-icons {
  font-size: 15px;
}

.contact-box > li .link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.contact-box > li .tool {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  padding: 4px;
  text-align: center;
}

.contact-box > li .tool a {
  display: block;
  margin-bottom: 2px;
}

.contact-box > li .tool .material-icons {
  font-size: 1.35rem;
}

.contact-box > li:hover .tool {
  display: block;
}

.contact-box > li:hover .tool svg {
  width: 16px;
  height: 16px;
}

#modal,
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.modal-fix {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 999;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  min-width: 315px;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.modal-direct-new .modal-content {
  min-width: 40%;
  overflow: hidden;
}

.modal-direct-new .btn-count-down {
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

#modal.hide {
  display: none;
}

.logo-view p {
  text-align: center;
}

.form-add-mes button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  outline: none;
}

.form-add-mes .loader-spinner {
  border: 0.25rem solid #1e88e5;
  border-radius: 50%;
  border-top: 0.25rem solid #fff;
  border-left: 0.25rem solid #fff;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1.5s linear infinite;
  border-bottom: 0.25rem solid #fff;
  margin-left: 10px;
}

.form-pending button,
.form-pending input {
  pointer-events: none;
}

.form-add-mes textarea {
  max-width: 100%;
  max-height: 100%;
  resize: auto;
  min-height: 80px;
  padding: 10px;
}

.tool-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0px 7px; */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.tool-filter a {
  text-align: center;
  padding: 10px;
  flex: auto;
  position: relative;
}

.tool-filter a.active {
  color: #2196f3;
  pointer-events: none;
}

.tool-filter a.active:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 3px;
  background: #2196f3;
}

.tool-filter a:hover,
.tool-filter a:focus {
  text-decoration: unset;
  outline: none;
}

.tool-filter-nc a {
  text-align: center;
  padding: 10px;
  display: inline-block;
}

.tool-search {
  display: flex;
  justify-content: space-between;
  background: #ebebeb;
  padding: 10px 10px;
  border-right: 5px solid #fff;
}

.tool-filter-m {
}

.tool-filter-tag {
  display: flex;
  padding-left: 15px;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: -3px;
}

.tool-filter-tag a {
  text-align: center;
  padding: 9px;
  display: inline-block;
  margin: 0px 5px 5px 0px;
  position: relative;
}

.tool-filter-tag a i {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tool-filter-text {
  user-select: none;
  flex: auto;
  width: 100%;
}

.tool-filter-text input {
  padding: 5px 5px 5px 10px;
  background: #fff;
  height: 100%;
  box-sizing: border-box;
  margin: 0px;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #d4dbe0;
  max-height: 40px;
  width: 100%;
}

.tool-filter-m .filter-read-state {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.tool-filter-m .filter-read-state a {
  padding: 0px 5px;
  position: relative;
  height: 20px;
}

.tool-filter-m .filter-read-state a i {
}

.error_log {
  padding: 5px 10px;
  font-size: 15px;
  background: #d86a6a;
  color: #fff;
}

#sidepanel .load-spinner-box {
  top: 12rem;
}

.contact-box .text-box .list-tags {
  margin-top: 5px;
  max-width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.contact-box .text-box .list-tags .item-tag {
  background: #2196f3;
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
  display: inline-block;
  font-size: 11px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.contact-box .text-box .list-tags .item-tag-2 {
  background: #2196f3;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 12px;
  height: 12px;
}

.table-h {
  margin: 10px 0px;
}

.table-h thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-h td {
  text-align: center;
  padding: 5px;
}

.justify-start {
  justify-content: start;
}

.table-option-tags {
  /* border-bottom: 2px solid #dee2e6; */
  table-layout: fixed;
}

.table-option-tags .name_tag {
  width: 45%;
}

.table-option-tags .action a {
  display: inline-block;
  margin-right: 5px;
}

.table-option-tags td,
.table-option-tags th {
  padding: 2px 5px;
  text-align: left;
}

.table-option-tags td span.text {
  padding: 5px;
  display: inline-block;
}

.table-option-tags td input {
  font-size: 10pt;
  margin: 0px;
  padding: 5.5px 5px;
  border: 1px solid #cccc;
  border-radius: 5px;
  width: 100%;
}

.table-option-tags td .item-tag-h {
  user-select: auto;
}

.table-option-tags td.action a {
  margin-right: 15px;
}

#modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  -x-backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.33);
  z-index: 999;
}

.modal-evt {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.modal-body {
  z-index: 1;
  position: relative;
}

.source-contact {
  position: sticky;
  font-size: 15px;
  color: #263a53;
  display: flex;
  padding: 5px 5px 5px;
  justify-content: space-between;
  top: 5px;
  left: 0;
  background-color: #e5ded8;
  z-index: 2;
  margin-bottom: 15px;
  width: 96%;
  margin: auto;
}

.source-contact .avatar {
  flex-shrink: 0;
}

.source-contact .avatar img {
  /* object-fit: cover;
    width: 100%;
    height: 100%;
    display: inline-block; */
}

.source-contact .content {
  width: 40%;
  margin-bottom: 5px;
  padding: 0px 15px;
  font-size: 14px;
  color: #757575;
}

.source-contact .image {
  flex-shrink: 0;
  min-width: 75px;
  max-width: 35%;
  max-height: 35%;
  position: relative;
}

.source-contact .image img {
  max-width: 100%;
  max-height: 65px;
  /* border-radius: 5px; */
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.source-contact .icon-link-post {
  margin-left: 5px;
  border-radius: 50%;
  background: #2196f3;
  color: #fff;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  position: absolute;
  right: -5px;
  top: -5px;
}

.source-contact .link-post {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.source-contact .icon-link-post i {
  transform: rotate(-45deg);
  font-size: 16px;
}

.tooltip {
  position: fixed;
  opacity: 1;
  transition: 0.2s opacity ease-in-out;
  z-index: 9999;
}

.tooltip.show {
  opacity: 1;
}

.tooltip.hide {
  opacity: 0;
}

.tooltip-content {
  /* position: absolute; */
  background-color: #000;
  text-align: center;
  color: #fff;
  /* transform: translateX(-50%); */
  white-space: nowrap;
  border-radius: 4px;
  padding: 4px 8px;
}

.tooltip-content:before {
  content: "";
  position: absolute;
}

/* POSITION TOP */
.tooltip-content.top {
  /* bottom: 100%;
    left: 50%; */
  /* margin-bottom: 10px; */
}

.tooltip-content.top:before {
  top: 100%;
  left: 50%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  transform: translateX(-50%);
}

.tooltip-content.right:before {
  top: 50%;
  left: -5px;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-right: 5px solid #000;
  transform: translateY(-50%);
}

.tooltip-content.left:before {
  top: 50%;
  right: -5px;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-left: 5px solid #000;
  transform: translateY(-50%);
}

.chat-box .bubble div.message-content #video-mes {
  outline: none;
  display: inline-block;
}

.chat-box .bubble div.message-content #video-mes video {
  outline: none;
}

/* .chat-box .bubble div.message-content .list-img{
    display: flex;
    flex-wrap: wrap;
  }
  .chat-box .bubble div.message-content .list-img img{
    flex: auto;
  } */
.hide {
  opacity: 0;
}

.show {
  opacity: 1;
}

.img-ready {
  opacity: 1;
  transition: 0.2s opacity ease-in-out;
}

.avatar-h {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 50%;
}

.message-content .media-share {
  position: relative;
}

.message-content .media-share .media-owner {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.message-content .media-share .media-owner .media-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.message-content .media-share .media {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 18rem;
}

.message-content .media-share .media img {
  border-radius: 5px;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.message-content .media-share .media-content {
  width: 100%;
  margin-top: 5px;
  white-space: normal;
}

.message-content .media-share .media-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

#filter-date {
  position: fixed;
  top: 100px;
  left: 100px;
  z-index: 9999;
  padding: 10px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  display: none;
}

#filter-date.show {
  display: block;
}

#filter-date .arrow {
  position: absolute;
  top: 50%;
  left: -21px;
  transform: translateY(-50%);
  border-color: transparent;
  border-right-color: #d4dbe0;
  border-width: 11px;
  border-style: solid;
}

#filter-date .arrow:after {
  content: "";
  left: 1px;
  bottom: -10px;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
  border-left-width: 0;
  border-right-color: #fff;
  position: absolute;
}

#filter-date .action-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.action-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.action-btn button {
  margin-left: 10px;
}

.rdrMonthAndYearPickers {
  /* display: none!important; */
}

.rdrDateDisplayItem input {
  margin: 0px;
  padding: 0px;
  height: 100% !important;
  line-height: unset !important;
}

.rdrWeekDay {
  font-weight: 600 !important;
  color: #000 !important;
}

.rdrStaticRange {
  border-radius: 5px;
  margin-bottom: 5px;
}

.PreviewArea .rdrStaticRangeLabel {
  background: rgb(236, 240, 241);
  border-radius: 5px;
}

.PreviewArea .rdrDefinedRangesWrapper {
  border-right: 0px;
}

.rdrInputRange:last-child {
  display: none;
}

#form-reply-fast {
  /* padding: 0px 16px; */
  /* margin-top: 20px; */
  /* position: absolute; */
  /* left: 0;
  bottom: 100%;
  right: 0;
  z-index: 99; */
  /* box-shadow: 0 0 3px 6px rgba(0,0,0,0.02); */
}

#form-reply-fast .list-reply {
  overflow-y: auto;
  max-height: 300px;
}

#form-reply-fast ul {
  /* overflow-y: scroll; */
  padding: 0px;
  margin-bottom: 0px;
}

#form-reply-fast ul li {
  padding: 10px;
  list-style: none;
  border-bottom: 1px solid rgb(244, 241, 241);
  cursor: pointer;
  background: #fff;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: rgb(74, 100, 108);
  position: relative;
  padding-right: 50px;
}

#form-reply-fast ul li i{
  margin-left: 10px;
}

#form-reply-fast ul li:hover{
  background-color: rgb(45, 158, 224);
  color: rgb(255, 255, 255);
}

#form-reply-fast ul li:hover i{
  color: rgb(255, 255, 255);
}

#form-reply-fast ul li.active {
  background-color: rgb(45, 158, 224);
  color: rgb(255, 255, 255);
}

#form-reply-fast ul li span {
  padding: 3px;
  color: rgb(130, 43, 161);
  border: 1px solid rgb(130, 43, 161);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  margin-right: 5px;
}

#form-reply-fast ul li .tool {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  transition: 0.3s all ease-in-out;
  z-index: 1;
}

#form-reply-fast ul li.active .tool a {
  color: #fff;
}

#form-reply-fast ul li:hover .tool {
  display: block;
}

#form-reply-fast .title {
  padding: 10px;
  background-color: rgb(176 190 197);
  display: flex;
  justify-content: space-between;
}

#form-reply-fast .title span, #form-reply-fast .title span a{
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #fff;
}
#form-reply-fast .title span i{
  color: #fff;
}

.add-reply {
  cursor: pointer;
}

.modal-h .modal-content {
  padding: 0px;
  overflow: auto;
}

.modal-content-lg {
  width: 60%;
}

.modal-content-md {
  width: 40% !important;
}

.modal-h .modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
}

.modal-h .modal-body {
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  padding: 20px;
}

.modal-h .modal-footer {
  padding: 10px 16px;
  text-align: right;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 9;
}

.modal-h .modal-form {
}

.modal-form .form-group {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.form-group-c {
  display: flex;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  align-items: center;
  width: 100%;
}

.form-group label {
  white-space: nowrap;
  margin: 5px 0px;
  margin-right: 14px;
}

.form-group-c .icon {
  padding: 0 11px;
  font-weight: 400;
  text-align: center;
  background-color: #fafafa;
  border-radius: 4px;
  transition: all 0.3s;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid #d9d9d9;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-form .form-group input {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  background-color: #fff;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-left: 10px;
  border: 0px;
}

.modal-form textarea {
  border: 0px;
  box-shadow: unset;
  height: 150px;
  padding-left: 10px;
  border-radius: 5px;
}

.modal-form .btn-add-reply-defaul {
  color: #de343b;
  font-weight: 400;
  padding: 2px 8px;
  border: 1px solid rosybrown;
  border-radius: 2px;
  background-color: floralwhite;
  cursor: pointer;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.form-group.error {
  color: #de343b;
}

.notify-mes {
  position: fixed;
  top: 60px;
  padding: 8px 25px;
  z-index: 99999;
  left: 50%;
  transform: translateX(-50%);
  background: #52647a;
  border-radius: 5px;
  color: #fff;
  animation: showNoty 0.5s ease-in-out;
  transition: 0.2s all ease-in-out;
  max-width: 500px;
  text-align: center;
}

.notify-mes a {
  color: #fff;
  text-decoration: underline;
}

.notify-mes a:hover {
  color: #fff;
  text-decoration: underline;
}

.notify-mes .icon-noti {
  margin-left: -12px;
  font-size: 14px;
  margin-right: 5px;
}

.notify-mes .close {
  position: absolute;
  right: 3px;
  top: 3px;
  color: #fff;
  font-size: 11px;
}

.notify-mes .close i {
  font-size: 14px;
}

@keyframes showNoty {
  0% {
    opacity: 0;
    top: -100px;
  }

  100% {
    opacity: 1;
    top: 50px;
  }
}

#send-message-panel .list-tags {
  display: flex;
  position: absolute;
  top: 0;
  left: 0px;
  justify-content: center;
  width: 100%;
  bottom: 100%;
  z-index: 98;
  flex-wrap: wrap-reverse;
}

#send-message-panel .list-tags .item-tag-h {
  text-align: center;
  margin: 0px;
  border-radius: 0px;
  opacity: 1;
  position: relative;
  flex: 1 0 20%;
  padding: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#send-message-panel .list-tags .item-tag-h:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

#send-message-panel .list-tags .item-tag-h.active:after,
#send-message-panel .list-tags .item-tag-h:hover:after {
  background-color: transparent;
}

.btn-manage-tool {
  margin: 0px;
  margin-left: 10px;
  position: relative;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.btn-manage-tool:hover {
  text-decoration: none;
}

.btn-manage-tool .icon-svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.btn-manage-tool svg {
  pointer-events: none;
}

.btn-manage-tool i {
  font-size: 18px;
}

.btn-manage-tool img {
  width: 22px;
  z-index: 0;
  pointer-events: none;
}

.btn-manage-tool.count-downt {
  position: relative;
}

.btn-manage-tool.count-downt span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 10px;
  margin-top: -1px;
  color: #fff;
  pointer-events: none;
  /* font-weight: bold; */
}

.modal-manage-tags .modal-content {
  top: 50%;
  max-height: 99%;
}

.modal-manage-tags .table-h thead th,
.modal-manage-tags .table-option-tags {
  border: 0px;
}

.list-phone {
  display: flex;
  flex-wrap: wrap;
  max-height: 100px;
  overflow: auto;
}

.list-phone .item-phone {
  flex: auto;
  margin: 5px;
  cursor: copy;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 13px;
  max-width: 50%;
  user-select: none;
}

.list-phone .item-phone i {
  margin-right: 5px;
  user-select: none;
}

.list-phone .item-phone:hover {
  background: #2196f3;
  color: #fff;
}

.link-pos {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.tool-top {
  display: flex;
  align-items: center;
  padding: 5px 5px;
}

.tool-top .select-tool {
  width: 100%;
  background: #fff;
  border: 0px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  margin-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.select-tool .text {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  box-sizing: border-box;
  user-select: none;
  height: 25px;
  padding: 5px 10px;
}

.tool-top .select-tool .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}

.tool-top .select-tool .select-tool-box {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  z-index: 1;
  margin-top: 8px;
  border-radius: 5px;
  display: none;
}

.tool-top .select-tool .select-tool-box.show {
  display: block;
}

.select-tool-box.hide {
  display: none;
}

.tool-top .select-tool .select-tool-box ul li {
  padding: 8px 10px;
  user-select: none;
}

.tool-top .select-tool .select-tool-box ul li:hover {
  background-color: #d1e2fa;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid black;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #000;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #000;
}

.tool-top .select-tool i {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.contact-box > li {
  position: relative;
  align-items: center;
}

.div_checkbox_cover {
}

.contact-box > li label,
.tool-top label {
  position: relative;
  border: 1px solid #bababa;
  background: #fff;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  cursor: pointer;
  flex-shrink: 0;
  z-index: 10;
  margin: 0px;
}

.contact-box > li label svg,
.tool-top label svg {
  position: absolute;
  transform: scale(0);
  width: 12px;
  height: 12px;
  color: #fff;
  transition: transform 0.15s ease-in-out;
}

.contact-box > li label input,
.tool-top label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.contact-box > li label input:checked ~ svg,
.tool-top input:checked ~ svg {
  transform: scale(1);
}

.div_checkbox_cover {
  border-radius: 50%;
  min-width: 30px;
  min-height: 30px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.div_checkbox_cover:hover {
  background: #d1e2fa;
  border: 1px solid #d1e2fa;
}

.div_checkbox_cover:hover label {
  border: 1px solid #176fe8;
}

/* loadin wrap */
.loading-m {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.1); */
  background: #fff;
}

#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #F00;
}

#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #0F0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #00F;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

#loading-content {
  border: 3px solid transparent;
  border-top-color: #4D658D;
  border-bottom-color: #4D658D;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #D4CC6A;
  border-bottom-color: #D4CC6A;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
    -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417C;
  border-bottom-color: #84417C;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
    -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader {
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 32px);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 4px solid #2196f3;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 4px solid #2196f3;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 4px solid #2196f3;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.history-direct {
  max-height: 200px;
  overflow: auto;
  width: 100%;
  padding-bottom: 0px;
  padding-top: 10px;
  margin-top: 10px;
  padding-right: 15px;
  background: rgb(236 239 241);
  border-radius: 5px;
}

.history-direct .image-message {
  max-width: 50%;
}

.modal-direct-new .title {
  font-size: 18px;
  color: #1e88e5;
  padding-left: 5px;
}

.row-preview-image .preview-image {
  /* padding: 5px; */
  display: inline-block;
  position: relative;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 100px;
  width: 100px;
  flex: 0 0 100px;
}

.row-preview-image .preview-image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}

.row-preview-image .preview-image .close {
  position: absolute;
  right: -5px;
  top: -2px;
  border-radius: 50%;
  background: #0288d1;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  overflow: hidden;
  font-size: 11px;
  padding: 0px;
  margin: 0px;
  min-width: auto !important;
  cursor: pointer;
  /* pointer-events: none; */
  z-index: 3;
  opacity: 1 !important;
}
.row-preview-image .preview-image .close:hover {
  opacity: 1 !important;
}

.row-preview-image .preview-image.image-upload {
  position: relative;
  /* overflow: hidden; */
}

.row-preview-image .preview-image.image-upload:before {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  content: "";
  bottom: 0px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.row-preview-image .preview-image.image-upload:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  background-color: #0288d1;
  height: 2px;
  width: 60%;
  z-index: 2;
}

.row-preview-image .preview-image.image-upload.success {
}

.row-preview-image .preview-image.image-upload.success .close {
  /* pointer-events: auto; */
  /* z-index: 3; */
}

.row-preview-image .preview-image.image-upload .file {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.row-preview-image .preview-image.image-upload .file .file-name{
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-preview-image .preview-image.image-upload img {
  z-index: 0;
}

.row-preview-image .preview-image.image-upload.success:after,
.row-preview-image .preview-image.image-upload.success:before {
  content: unset;
}

.form-add-mes-m {
  display: flex;
  align-items: center;
  flex: none;
}

.btn-count-down {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: red;
  color: #fff;
  font-size: 10px;
  width: 22px;
  height: 20px;
  text-decoration: none;
  margin-left: 10px;
  transform: translateY(2px);
}

.btn-count-down:hover {
  text-decoration: none;
  color: #fff;
}

.ant-tooltip-inner {
  min-height: unset !important;
  padding: 3px 6px !important;
  min-width: unset !important;
  text-align: center !important;
}

.filter-post {
  width: 60%;
}

.filter-post input {
  height: auto;
}

.filter-post .list-post {
  display: inline-block;
  width: 100%;
  border-right: 1px dotted rgb(204, 204, 204);
  padding-right: 8px;
}

.filter-post .select-post {
  display: inline-block;
  width: 100%;
  margin-left: 8px;
  vertical-align: top;
  position: relative;
  height: 100%;
}

.filter-post .list-post .list-all {
  border: 1px solid rgb(229, 227, 227);
  border-radius: 3px;
  margin-top: 10px;
}

.filter-post .post-infinite-scroll {
  height: 398px;
  overflow: hidden scroll;
  overflow-y: auto !important;
}

.filter-post .list-post-filter-item {
  height: 90px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(229, 227, 227);
  background-color: rgb(255, 255, 255);
  /* user-select: none; */
}

.filter-post .list-post-filter-item.selected {
  background-color: rgb(224, 238, 251);
}

.filter-post .list-post-filter-item .image {
  margin: auto 7px;
  width: 70px;
  height: 70px;
  border: 1px solid rgb(242, 239, 239);
  border-radius: 4px;
  background-position: center center;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.filter-post .list-post-filter-item .content {
  margin: auto;
  width: calc(100% - 80px);
  padding: 2px 5px 2px 0px;
}

.filter-post .list-post-filter-item .content .title {
  font-weight: 400;
  color: dodgerblue;
  line-height: 22px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.filter-post .list-post-filter-item .content .title .id-name {
  font-size: 14px;
}

.filter-post .list-post-filter-item .content .title .time {
  float: right;
  font-size: 12px;
  color: rgb(169, 166, 166);
  margin-right: 10px;
}

.filter-post .list-post-filter-item .notification-item {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-size: 15px;
  line-height: 23px;
  margin-right: 5px;
}

.filter-post .bottom {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-post .bottom button,
.select-post .bottom button {
  margin-left: 10px;
}

.select-post .bottom {
  text-align: right;
  width: 100%;
  margin-top: 5px;
  position: absolute;
  bottom: 0px;
}

.select-post .title {
  font-weight: 500;
  display: flex;
  margin-bottom: 13px;
  margin-top: 13px;
}

.select-post .title span {
  font-size: 13px;
  color: crimson;
  margin: 1px 0px 0px 5px;
}

.select-post .post-select {
  border: 1px solid rgb(217, 218, 219);
  border-radius: 4px;
  padding: 10px;
  max-height: 360px;
  overflow-y: auto;
}

.select-post .post-select .info-owner {
  display: flex;
  margin-bottom: 5px;
}

.select-post .post-select .info-owner .h-avatar {
  width: 35px;
  height: 35px;
}

.select-post .empty-post {
  color: rgb(136, 136, 136);
  text-align: center;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filter-post .list-post-filter-item .owner {
  display: flex;
  align-items: center;
}

.filter-post .list-post-filter-item .owner .h-avatar {
  width: 25px;
  height: 25px;
  margin-right: 8px;
}

.filter-post .list-post-filter-item .owner {
  font-size: 13px;
}

.source-owner {
  /* padding-top: 10px; */
}

.source-icon {
  display: flex;
  justify-content: flex-end;
  padding: 0px 5px;
  position: absolute;
  right: -5px;
  z-index: 0;
  bottom: -4px;
}

.contact-box li .info-source {
}

.source-icon img {
  width: 16px;
  height: 16px;
}

.source-icon span {
  font-size: 10px;
  color: #fff;
  background-color: #52647a;
  border-radius: 5px;
  padding: 0px 3px;
  white-space: nowrap;
  overflow: hidden;
}

.chat-menu-bar {
  padding-left: 10px;
  font-size: 26px;
  display: flex;
  align-items: center;
  margin: 0px;
}

.chat-menu-bar li {
  display: inline-block;
  width: 30px;
  text-align: center;
  color: #fff;
  margin-left: 5px;
}

.chat-menu-bar li a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgb(136, 136, 136);
  width: 1em;
  height: 1em;
}

.chat-menu-bar li svg {
  width: unset;
  color: #fff;
}

.chat-menu-bar li svg:hover {
  color: #fff !important;
}

.chat-menu-bar li a.active {
  background-color: dodgerblue !important;
}

.chat-menu-bar li a.active svg {
  color: #fff;
}

.chat-menu-bar li i {
  color: #fff;
  font-size: 16px;
}

.ant-message-notice-content {
  border-radius: 5px !important;
}

#side-caption-panel {
  background-color: #506dad;
  /* border-right: 5px solid #fff; */
  overflow: hidden;
  box-shadow: none;
  /* display: none; */
  /* border-left: 5px solid #fff; */
}

.list-owner-top {
  display: flex;
  align-items: center;
  padding: 3px 5px 3px 0px;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}

.list-owner-top .owner-avatar {
  width: 35px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.14);
}

.header .list-owners {
  position: relative;
  z-index: 1;
  /* margin: 0px 10px 0px 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header .list-owners .header-avatar {
  margin: 0px;
  position: relative;
}

.header .list-owners .header-avatar:not(:first-child) {
  margin-left: -12px;
}

.modal-page-chat .ant-modal-content,
.modal-page-chat .ant-modal-header {
  border-radius: 10px;
}

.modal-page-chat button {
  border-radius: 5px;
}

.modal-page-chat .list-owners {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.modal-page-chat .list-owners .item {
  flex: 0 0 20%;
  padding: 10px;
}

.modal-page-chat .list-owners .item .item-img {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
  cursor: pointer;
}

.modal-page-chat .list-owners .item .item-img.active:after {
  border: 2px solid #1890ff;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  z-index: 1;
}

.modal-page-chat .list-owners .item img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: 5px;
  height: 100%;
  user-select: none;
}

.modal-page-chat .list-owners .item .info-source {
  position: absolute;
  right: 20px;
  bottom: 18px;
}

.modal-page-chat .list-owners .item .info-source img {
  width: 16px;
  height: 16px;
}

.modal-page-chat .list-owners .item .info-source span {
  font-size: 10px;
  color: #fff;
  background-color: #52647a;
  border-radius: 5px;
  padding: 0px 3px;
  white-space: nowrap;
  overflow: hidden;
}

.modal-page-chat .list-owners .item .item-img .icon-check {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #fff;
  color: #1890ff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  overflow: hidden;
  border: 1px solid #1890ff;
  border-radius: 50%;
  z-index: 2;
}

.modal-page-chat .list-owners .item .item-img .icon-check i {
  font-size: 12px;
  color: #1890ff;
}

.modal-image .box-image img {
  max-width: 100%;
  object-fit: cover;
}

.modal-image .ant-modal-close {
  display: none;
}

.box-image {
  text-align: center;
}

.modal-image .ant-modal-content {
  background: transparent !important;
  box-shadow: unset !important;
  max-width: 70%;
  margin: auto;
}

.modal-image {
  width: fit-content !important;
}

.modal-image .ant-modal-footer {
  display: none;
}

.modal-image .ant-modal-body {
  padding: 0px;
}

.header .logo {
  width: 28rem;
}

.reaction {
  position: absolute;
  left: -18px;
  bottom: -4px;
  font-size: 15px;
  color: #fed428;
  background: #fff;
  border-radius: 20px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.reaction.other {
  color: red;
}

.chat-box li.left .reaction {
  left: unset;
  right: -18px;
}

#info-view-content .note-contact {
  margin: 0px;
  border: 0px;
  height: unset;
  white-space: unset;
  line-height: 22px;
  font-size: 14px;
  padding: 5px;
  overflow: auto;
  min-height: 80px;
}

#info-view-content .note-contact.hide {
  display: none;
}

#info-view-content .head {
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
}

#info-view-content .btn-edit {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
}

#info-view-content .list-btn {
  margin-top: 10px;
}

#info-view-content .list-btn button {
  margin-right: 10px;
}

#info-view-content .btn-edit:hover:after {
  /* text-decoration: underline; */
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1890ff;
}

#info-view-content .btn-edit i {
  font-size: 14px;
  margin-left: 5px;
}
.chat-box .avatar-box.icon-read-mes {
  position: absolute;
  bottom: 5px;
  margin: 0px;
  right: 5px;
}

#form-create-order {
  padding: 10px;
  height: 100%;
  overflow: auto;
}
.profile-custormer {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
#form-create-order .ant-form-item {
  padding: 5px;
  margin: 0px;
}
.product-order {
  padding: 5px 10px;
  border: 1px solid #e0e0e0;
  margin-top: 10px;
  border-radius: 6px;
}
.product-order .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-order .head .title {
  font-size: 14px;
}
.product-order .list-product {
  table-layout: fixed;
  margin: -5px;
  margin-bottom: 10px;
}
.product-order .list-product thead th {
  font-size: 12px;
  padding: 5px;
}
.product-order .list-product td {
  padding: 5px;
  overflow: hidden;
  vertical-align: baseline;
  position: relative;
}
.product-order .list-product .btn-remove-product {
  position: absolute;
  top: 5px;
  right: 2px;
  color: #506dad;
  display: none;
  cursor: pointer;
  padding: 2px;
}
.product-order .list-product td:hover .btn-remove-product {
  display: block;
}

.product-order .list-product .ant-form-item {
  padding: 0px !important;
}
.product-order .list-product .ant-input-number {
  width: 100%;
}
.product-order .list-product .ant-form-item-explain {
  min-height: unset;
}
#form-create-order .product-order input {
  border: ;
}
.product-order .list-product thead tr {
  border-bottom: 1px solid #e0e0e0;
}
.product-order .list-product tr {
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  transform: scale(1, 1);
}
.product-order .list-product tr .btn-delete-product {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateX(-50%);
}
#form-create-order input,
#form-create-order .ant-input-number,
#form-create-order button,
#form-create-order .ant-select-selector {
  /* border-radius: 5px; */
  font-size: 13px;
}
#form-create-order input {
  color: #101010;
}
#form-create-order .profile-custormer input,
#form-create-order .profile-custormer .ant-select-selector {
  height: 32px;
  background-color: #ebebeb;
  border: none;
}
#form-create-order .order-bottom input,
#form-create-order .ant-input-number,
#form-create-order .product-order input:not(.product_name) {
  background-color: #ebebeb;
  border: none;
  text-overflow: ellipsis;
}
#form-create-order .product-order input.product_name {
  background-color: #ebebeb;
  text-overflow: ellipsis;
}
#form-create-order .ant-input-number-input-wrap {
  background-color: #fff;
}
#form-create-order input::placeholder,
#form-create-order textarea::placeholder,
#form-create-order .ant-select-selection-placeholder,
#form-create-order .ant-select-selection-item,
#form-create-order .ant-select-item-option-content,
.select-order .ant-select-item-option-content {
  font-size: 13px;
}
#form-create-order .order-bottom {
  padding: 5px;
  border: 1px solid #e0e0e0;
  margin-top: 10px;
  border-radius: 6px;
}
#form-create-order .order-note {
  display: flex;
  align-items: center;
}
#form-create-order .ant-form-item-control-input-content:focus {
  border: none;
}
#form-create-order .ant-form-item-explain {
  font-size: 13px;
  padding: 2px 3px;
}
#form-create-order .ant-select-selection-item,
#form-create-order .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}
#message-view {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
}
.channel-alias {
  font-size: 13px;
  font-weight: 500;
}
.form-send-mes-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.handle-sale .bubble {
  background: transparent !important;
}
.handle-sale-1 svg {
  fill: red;
}
.handle-sale-1 span,
.handle-sale-1 a {
  color: red !important;
}
.handle-sale-2 svg {
  fill: #53647a;
}
.handle-sale-2 span,
.handle-sale-2 a {
  color: #53647a !important;
}
.modal-product .item{
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.075);
  cursor: pointer;
}
.modal-product .item .img{
  height: 120px;
  position: relative;
  overflow: hidden;
}
.modal-product .item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  transition: 0.3s all ease-in-out;
}
.modal-product .item .img .category{
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  padding: 2px 2px 2px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  transition: 0.3s all ease-in-out;
}
.modal-product .item:hover img{
  transform: scale(1.2);
}
.modal-product .item:hover .category{
  background-color: rgba(0, 0, 0, 1);
}
.modal-product .item .content{
  padding: 5px 5px 5px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.modal-product .item .content .title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-product .ant-tabs-tabpane{
  min-height: 100px;
  /* padding-top: 10px; */
}

.modal-product .row.product > [class*="col-"]{
  margin-bottom: 15px;
  padding-right: 10px;
  padding-left: 10px;
}
.modal-product .row.product{
  margin-right: -10px;
  margin-left: -10px;
}
.row-preview-image{
  display: flex;
  align-items: center;
  overflow: auto;
  width: 100%;
  padding-bottom: 5px;
}
/*response*/
@media (max-width: 1366px) {
  .chat-box {
    padding-bottom: 60px !important;
    padding-top: 10px;
  }

  .caption-panel {
    height: 40px;
    padding: 0px 5px;
  }
  #info-caption-panel {
    height: 42px;
    min-height: 42px;
  }

  .panel-title {
    font-size: 1.3rem;
  }

  #topic-caption-panel .avatar-box {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  #topic-caption-panel .avatar-box img {
    vertical-align: top;
  }

  #sendMessage {
    height: 45px;
  }
}

@media (max-width: 1024px) {
  #info-view {
    display: none;
  }
}

@media (max-width: 960px) {
  #root-chat {
    padding: 0;
  }

  .detail-contact #sidepanel,
  #info-view {
    display: none;
  }
}

@media (max-width: 720px) {
  
}

@media (max-width: 640px) {
  #app-container {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  #sidepanel {
    width: 100%;
  }

  #sidepanel:not(.tab-login) {
    /* display: none!important; */
  }

  #info-view {
    width: 100%;
  }

  #dummy-view {
    display: none !important;
  }

  #hide-message-view {
    display: block !important;
  }

  .chat-box .bubble div.message-content {
    max-width: auto;
  }
  .chat-box .bubble {
    max-width: 85%;
  }

  .chat-box .menuTrigger {
    opacity: 1;
    /* right: -25px */
  }

  .contact-box > li .tool {
    display: block;
  }

  .contact-box > li .tool .material-icons {
    font-size: 1.5rem;
  }

  .contact-box > li .tool a {
    display: inline-block;
    margin: 0px;
    margin-left: 5px;
  }

  #sidebar-filter {
    flex-shrink: unset;
  }

  #topic-view {
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0.3;
  }

  #login-view {
    width: 100%;
    height: 100%;
  }

  .tooltip {
    display: none;
  }

  .modal-h .modal-content {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    transform: unset;
    max-height: 100%;
    border-radius: 0px;
  }

  .rdrDefinedRangesWrapper {
    display: none !important;
  }

  #filter-date {
    width: calc(100% - 60px);
  }

  #messages-panel {
    padding: 5px 0px 100px;
  }

  .chat-box.group {
    margin: 0px;
  }

  .chat-box .menuTrigger {
    padding-left: 10px;
  }

  .chat-box .right .menuTrigger {
    padding-right: 10px;
  }

  .modal-direct-new .modal-content {
    min-width: 90%;
  }

  .notify-mes {
    max-width: 350px;
  }

  .modal-image .ant-modal-content {
    max-width: 80%;
  }

  .ant-tooltip {
    display: none !important;
  }

  .ant-popover {
    width: 100%;
  }

  .ant-popover .ant-col-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .modal-content-md {
    width: 100% !important;
  }

  #send-message-panel {
    /* height: 5rem; */
    /* position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; */
  }
  #sidebar-filter.chat-view {
    display: none;
  }
  .detail-contact .header {
    /* display: none; */
  }
  .chat-box .inline-image{
    max-width: 100%!important;
  }
}

.view-message-disable .menuTrigger{
  display: none;
}
.view-message-disable .panel-form-row{
  pointer-events: none;
}
.view-message-disable #info-caption-panel #create-booking.info-tab{
  pointer-events: none;
}